<template>
  <v-row justify="center">
    <v-dialog v-model="openDialog" persistent max-width="600px">
      <v-card>
        <v-container fluid>
          <v-img src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg" height="100px"></v-img>
          <v-card-title>
            <span class="headline" style="text-transform: uppercase">Manage avatar</span>
          </v-card-title>
          <v-divider></v-divider>

          <v-card-text>
            <v-file-input v-model="avatarFile" accept="image/*" label="Select avatar"></v-file-input>
            <v-btn color="blue darken-1" @click="uploadAvatar" :disabled="!avatarFile" :loading="loading">
              Update avatar
            </v-btn>
          </v-card-text>

          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" v-on:click="$emit('close-dialog')" :loading="loading">
              Close
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import {AXIOS} from "../../../../http-commons";

  export default {
    props: ['open-dialog'],
    name: "AvatarDialog",
    data: () => ({
      avatarFile: null,
      loading: false
    }),
    methods: {
      uploadAvatar() {
        this.loading = true;
        let action = 'avatar';
        let objectType = 'Avatar';
        this.removeAvatarFromFS();
        const formData = new FormData();
        formData.append('file', this.avatarFile);
        formData.append('userId', this.currentUser.id);
        AXIOS.post(`/api/avatarUpload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(response => {
            this.loading = false;
            this.userNotification(response, action, objectType)
            this.$emit('close-dialog');
          })
      },
      removeAvatarFromFS() {
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        AXIOS.post('/api/removeAvatarFromFS', params)
          .then(response => {
            this.response = response.data;
          })
      },
    },
  }
</script>

<style scoped>

</style>
