<template>
  <Loader type="general" v-if="loading"></Loader>
  <v-container id="user-profile" fluid tag="section" v-else-if="!loading">
    <AvatarDialog :open-dialog="avatarDialog" v-on:close-dialog="avatarDialog=false"></AvatarDialog>
    <v-row justify="center">
      <v-col cols="12" md="4" sm="12" v-if="tariffPlan">
        <base-material-card class="v-card-profile" :avatar="avatarWithIcon.icon">
          <v-card-text class="text-center">
            <h4 class="display-2 font-weight-light mb-3 black--text">
              {{userDB.name}} {{userDB.surname}}
            </h4>

            <p class="font-weight-regular grey--text">
              {{$t('user-page.your-tariff-plan')}}: <strong>{{tariffPlan.planName}}</strong>
            </p>

            <v-col cols="12">
              <p class="font-weight-regular grey--text">
                {{$t('user-page.file-storage-used')}}: <strong>{{(calculateFileQuota/1000000).toFixed(0)}} /
                {{(tariffPlan.fileStorageLimit/ 1000000).toFixed(0)}} {{$t('units.mb')}}</strong></p>

              <v-progress-linear v-model="calculateFileStoragePercent" height="18" :color="setFileColor">
                <strong style="color: white">{{ calculateFileStoragePercent.toFixed(1) }}%</strong>
              </v-progress-linear>
            </v-col>

            <v-col cols="12">
              <p class="font-weight-regular grey--text">
                {{$t('user-page.data-storage-used')}}: <strong>{{calculateDataQuota}} /
                {{tariffPlan.dataStorageLimit}} {{$t('user-page.records')}}</strong>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon right v-on="on" style="cursor: pointer">mdi-information-outline</v-icon>
                  </template>
                  <span>
                    {{$t('user-page.orders')}}: {{this.orders.length}} ({{(this.orders.length/tariffPlan.dataStorageLimit*100).toFixed(1)}}%)<br>
                    {{$t('user-page.agencies')}}: {{this.agencies.length}} ({{(this.agencies.length/tariffPlan.dataStorageLimit*100).toFixed(1)}}%)<br>
                    {{$t('user-page.tasks')}}: {{this.tasks.length}} ({{(this.tasks.length/tariffPlan.dataStorageLimit*100).toFixed(1)}}%)<br>
                    {{$t('user-page.managers')}}: {{this.managers.length}} ({{(this.managers.length/tariffPlan.dataStorageLimit*100).toFixed(1)}}%)<br>
                    {{$t('user-page.payers')}}: {{this.payers.length}} ({{(this.payers.length/tariffPlan.dataStorageLimit*100).toFixed(1)}}%)
                  </span>
                </v-tooltip>
              </p>
              <v-progress-linear v-model="calculateDataStoragePercent" height="18"
                                 :color="setDataColor">
                <strong style="color: white">{{ calculateDataStoragePercent.toFixed(1) }}%</strong>
              </v-progress-linear>
            </v-col>

            <v-row justify="center">
              <v-col cols="12">
                <v-btn color="success" class="mr-0" @click="handleLogout">
                  {{$t('buttons.logout')}}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </base-material-card>

        <v-row dense v-if="!loading">
          <v-col v-for="(order, i) in recentOrders" :key="i" cols="12" md="12" sm="12">
            <router-link :to="{ path: '/order/' + order.orderId }" style="text-decoration: none; color: inherit;">
              <v-card class="mx-auto my-1">
                <v-list-item three-line>
                  <v-list-item-content>
                    <div class="text-overline mb-4">
                      <v-chip :color="order.status.statusColor" text-color="white" label>
                        {{order.orderCode}}
                      </v-chip>

                    </div>
                    <v-list-item-title class="text-h5 mb-1">
                      {{order.agency.agencyName}}
                    </v-list-item-title>
                    <v-list-item-subtitle>{{order.description.substr(0,60)}}...</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-avatar tile width="125" height="50" right>
                    <v-img :src="require(`@/assets/agencylogos/${order.agency.logoPath}`)"></v-img>
                  </v-list-item-avatar>
                </v-list-item>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-chip label outlined :color="order.status.statusColor">
                    {{order.status.statusName}}
                    <v-icon right :color="order.status.statusColor">
                      mdi-check-circle-outline
                    </v-icon>
                  </v-chip>
                </v-card-actions>
              </v-card>
            </router-link>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" md="8" sm="12">
        <base-material-card icon="mdi-badge-account-outline">
          <h4 class="display-2 font-weight-light mb-3 ">
            {{$t('user-page.user-information')}}
          </h4>
          <validation-observer ref="observer" v-slot="{ invalid }">
            <v-form>
              <v-container class="py-0">
                <v-row>
                  <v-col cols="12" md="4">
                    <validation-provider :rules="{required:true,min:5,max:18,alpha_dash:true}" v-slot="{ errors }">
                      <v-text-field v-model="userDB.username" class="purple-input" :label="$t('user-page.username')"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field v-model="userDB.mobile" :label="$t('user-page.mobile')"/>
                  </v-col>

                  <v-col cols="12" md="4">
                    <validation-provider :rules="{required:true,email:true}" v-slot="{ errors }">
                      <v-text-field :label="$t('user-page.email')" class="purple-input" v-model="userDB.email"/>
                      <span class="red--text font-weight-light">{{ errors[0] }}</span>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field :label="$t('user-page.first-name')" v-model="userDB.name" class="purple-input"/>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field :label="$t('user-page.last-name')" v-model="userDB.surname" class="purple-input"/>
                  </v-col>

                  <v-col cols="12" md="4">
                    <v-text-field :label="$t('user-page.father-name')" v-model="userDB.fatherName"
                                  class="purple-input"/>
                  </v-col>

                  <v-col cols="12" class="text-right">
                    <v-col md="12">
                      <v-btn color="success" class="mr-0" @click="avatarDialog=true">
                        {{$t('buttons.change-avatar')}}
                      </v-btn>
                    </v-col>
                    <v-col md="12">
                      <v-btn color="success" class="mr-0" @click="update" :disabled="invalid">
                        {{$t('buttons.update-profile')}}
                      </v-btn>
                    </v-col>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </validation-observer>
        </base-material-card>

        <validation-observer ref="observer" v-slot="{ invalid }">
          <base-material-card icon="mdi-shield-key-outline">
            <h4 class="display-2 font-weight-light mb-3">
              {{$t('user-page.change-password')}}
            </h4>
            <v-card-text>
              <validation-provider :rules="{required:true,min:5,max:18}" v-slot="{ errors }">
                <v-text-field :label="$t('user-page.old-password')" v-model="oldPassword"></v-text-field>
                <span class="red--text font-weight-light">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider :rules="{required:true,min:5,max:18}" v-slot="{ errors }">
                <v-text-field :label="$t('user-page.new-password')" v-model="password" type="password"></v-text-field>
                <span class="red--text font-weight-light">{{ errors[0] }}</span>
              </validation-provider>
              <validation-provider :rules="{required:true,min:5,max:18}" v-slot="{ errors }">
                <v-text-field :label="$t('user-page.confirm-password')" type="password"
                              v-model="confirmPassword"></v-text-field>
                <span class="red--text font-weight-light">{{ errors[0] }}</span>
              </validation-provider>
              <v-alert v-if="message" :type="success ? 'success':'error'" prominent transition="scale-transition"
                       dismissible @input="message=''">{{message}}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-col cols="12" class="text-right">
                <v-btn color="success" @click="updatePassword" :disabled="invalid">
                  {{$t('buttons.change-password')}}
                </v-btn>
              </v-col>
            </v-card-actions>
          </base-material-card>
        </validation-observer>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import Loader from "../../../components/Loaders/Loader";
  import {AXIOS} from "../../../../http-commons";
  import {mapState} from "pinia";
  import {useOrders} from '../../../stores/ordersStore'
  import {useUser} from "../../../stores/userStore";
  import {useAgencies} from "../../../stores/agenciesStore";
  import {usePayers} from "../../../stores/payersStore";
  import {useFiles} from "../../../stores/filesStore";
  import {useManagers} from "../../../stores/managersStore";
  import {useTasks} from "../../../stores/tasksStore";
  import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
  import {alpha_dash, confirmed, email, max, min, required} from "vee-validate/dist/rules";
  import AvatarDialog from "./AvatarDialog";

  const agencyStore = useAgencies()
  const ordersStore = useOrders()
  const userStore = useUser()
  const payersStore = usePayers()
  const filesStore = useFiles()
  const managersStore = useManagers()
  const tasksStore = useTasks()

  extend('required', {
    ...required,
    message: 'The field is required'
  });

  extend('min', {
    ...min,
    message: 'Minimum length is 5 characters'
  });
  extend('max', {
    ...max,
    message: 'Maximum length is 18 characters'
  });
  extend('alpha_dash', {
    ...alpha_dash,
    message: 'The field may contain alpha-numeric characters, dashes and underscores'
  });
  extend('email', {
    ...email,
    message: 'Only valid e-mail address allowed'
  });
  extend('confirmed', {
    ...confirmed,
    message: 'Password does not match'
  });

  export default {
    components: {
      Loader, ValidationProvider, ValidationObserver, AvatarDialog
    },
    name: 'UserProfile',
    data() {
      return {
        tariffPlan: [],
        avatarDialog: false,
        password: '',
        confirmPassword: '',
        oldPassword: '',
        success: null,
        message: ''
      }
    },
    methods: {
      async updateNeededCheck() {
        if (!this.files.length) {
          filesStore.getAllFiles()
        }
        if (!this.agencies.length) {
          agencyStore.getAgencyList()
        }
        if (!this.tasks.length) {
          tasksStore.populateTasks()
        }
        if (!this.managers.length) {
          managersStore.populateManagers()
        }
        if (!this.payers.length) {
          payersStore.populatePayers()
        }
        if (!this.orders.length) {
          let res = await ordersStore.getOrdersAsync()
          ordersStore.getRecentOrders()
          ordersStore.generateNotifications(this.tasks, this.calculateFileStoragePercent, this.calculateDataStoragePercent)
        }
      },
      getEntities() {
        this.tariffPlan = this.userDB.tariffPlan
        console.log("PLa: ", this.tariffPlan)
      },
      updatePassword() {
        this.loading = true;
        let params = new URLSearchParams();
        params.append('password', this.password);
        params.append('oldPassword', this.oldPassword);
        params.append('userId', this.currentUser.id);

        AXIOS.post('/api/user/updatePassword', params)
          .then(response => {
            if (response.data === 'succ') {
              this.success = true;
              this.message = this.$t('messages.password-changed')
              this.loading = false;
            }
            if (response.data === 'no match') {
              this.success = false;
              this.message = this.$t('messages.password-incorrect')
              this.loading = false;
            }
            if (response.data === 'no reuse') {
              this.loading = false;
              this.success = false;
              this.message = this.$t('messages.no-password-reuse')
            }
            this.password = '';
            this.confirmPassword = '';
            this.oldPassword = '';
          })
          .catch(e => {
            this.loading = false;
            this.errors.push(e)
          })
      },
      handleLogout() {
        agencyStore.$reset()
        payersStore.$reset()
        userStore.$reset()
        ordersStore.$reset()
        filesStore.$reset()
        managersStore.$reset()
        tasksStore.$reset()
        this.$router.push('/');
        this.$store.dispatch('auth/logout');
      },
      update() {
        let action = 'update';
        let objectType = 'User'
        let user = this.userDB;
        let params = new URLSearchParams();
        params.append('userId', this.currentUser.id);
        params.append('username', user.username);
        params.append('name', user.name);
        params.append('surname', user.surname);
        params.append('fatherName', user.fatherName);
        params.append('email', user.email);
        params.append('mobile', user.mobile);
        AXIOS.put(`/api/updateUser`, params)
          .then(response => {
            this.userNotification(response, action, objectType);
          })
      },
    },
    computed: {
      ...mapState(useOrders, ['orders', 'recentOrders', 'loading', 'notifications']),
      ...mapState(useUser, ['userDB']),
      ...mapState(useAgencies, ['agencies']),
      ...mapState(usePayers, ['payers']),
      ...mapState(useFiles, ['files']),
      ...mapState(useManagers, ['managers']),
      ...mapState(useTasks, ['tasks']),
      calculateFileQuota() {
        return this.files
          .map((file) => file.fileSize)
          .reduce(function (previous, current) {
            return previous + current;
          }, 0);
      },
      calculateDataQuota() {
        return this.orders.length + this.tasks.length + this.agencies.length + this.managers.length + this.payers.length
      },
      calculateDataStoragePercent() {
        return (this.calculateDataQuota) / (this.tariffPlan.dataStorageLimit) * 100;
      },
      calculateFileStoragePercent() {
        return (this.calculateFileQuota / 1000000) / (this.tariffPlan.fileStorageLimit/ 1000000) * 100;
      },
      setDataColor() {
        let a = this.calculateDataStoragePercent;
        if (a >= 75 && a < 90) {
          return 'amber';
        }
        if (a >= 90) {
          return 'red';
        } else {
          return 'primary';
        }
      },
      setFileColor() {
        let a = this.calculateFileStoragePercent;
        if (a >= 75 && a < 90) {
          return 'amber';
        }
        if (a >= 90) {
          return 'red';
        } else {
          return 'primary';
        }
      },
      avatarWithIcon() {
        return {
          ...this.userDB,
          icon: this.userDB.photoURL && require(`@/assets/user-avatars/${this.userDB.photoURL}`)
        }
      },
    },
    async created() {
      await userStore.getUserAsync()
      this.getEntities();
      this.updateNeededCheck();
    },
    mounted() {
      if (!this.currentUser) {
        this.$router.push('/auth');
      }
    }
  };
</script>
